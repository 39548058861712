import React from "react";
import { Col, Container, Row } from 'react-bootstrap';
import 'animate.css';
import awesome from "../images/mask.png";
import "./experienceContent.css";

function ExperienceContent() {
  return (
    <section className='experience' id='experienc'>
      <Container>
        <Row className='align-item-cente rows'>
          <Col size={12} md={6}>
        
            <h2>An experience like never before.</h2>
            
            <p>Find out why the Ultainfinity Airdrops and Bounties programs are the biggest ever airdrop & bounty program.</p>
         
          </Col>

          <Col size={12} md={6}>
            
             <div className='awesome'>
                <img className='imgawesome' src={awesome} alt="What we offer"/>
                </div>
            
          </Col>

        </Row>
      </Container>
    </section>
  );
}

export default ExperienceContent;
