import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import ExperienceContent from './components/ExperienceContent/ExperienceContent';
import FourthSection from './components/fourthsection/FourthSection';
import FifthSection from './components/fifthsection/FifthSection';
import Overview from './components/Overview/Overview';
import Unlimited from './components/unlimited/Unlimited';
import Footer from './components/footer/Footer';
import RegulatoryInfo from './components/regulatoryinfo/RegulatoryInfo';
import Unbeatable from './components/unbeatable/Unbeatable';
import WaitListPage from './components/waitlistpage/WaitListPage';
import CarouselPage from './components/carouselsection1/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import Outreach from './components/outreach/Outreach';
import Faq from './components/faqs/Faq';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {SliderData} from './components/slider/SliderData';
import Chart from './components/chart/Chart';
import Token from './components/token/Token';


function Allroute() {
  return (
    <div className="App">
      <Header/>
      <ExperienceContent/>
      <Overview/>
      <FourthSection/>
      <FifthSection/>
      <CarouselPage slides={SliderData} />
      <Outreach/>
      <Faq/>
      {/* <Slidercarousel/> */}
      <Unlimited/>
      <Chart/>
      <Token/>
      <WaitListPage/>
      
      <Unbeatable/>
      <RegulatoryInfo/>
      <Footer/>
    </div>
  );
}

export default Allroute;
