import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './unlimited.css';
import goldlock from '../images/goldlock.png';

function cl () {
  return (
    <section className='unlimited' id='unlimited'>
      <Container>
        <Row className="align-item-center body">
          <Col size={12} md={6} xl={8}>
            <h2>UNLIMITED ENTRIES.</h2>
            <p>NOW, THERE ARE NO EXCUSES</p>
          </Col>

          <Col size={12} md={6} xl= {4} className=' bold'>          
           <img src={goldlock} alt="goldImg"/>               
          </Col>

        </Row>
      </Container>
    </section>
  )
}

export default cl 