import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './process.css';
import {  useEffect } from "react";
import { CgArrowLongRight } from "react-icons/cg";
import { Link } from 'react-router-dom';


function Process() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className='process' id='process'>
      <Container>
        <Row className='align-items-center'>
          <Col size={12} md={11} xl={11} className='col'>
            <h2>Application Process</h2>
            <p>Our application process is simple, straight-forward and void of complexities. The Ultainfinity Cash for Charity is only for organisations that are working with the vision to increase the wealth-power of the members of their local communities. Please note that you may be required to provide information about your organisation, its operations and successes and also your representation of the organisation. </p>
            <p>You will also be required to upload documents. All uploads must be in one of the following file formats - .pdf, .doc, and .docx </p>
            <p>Deadline for application is September 22nd, 2023. 
            </p>
            <p style={{color: "white"}}>Visit <a href="https://ultainfinitywealthlaunchpad.com/" target="_blank" style={{color: "white", textDecorationStyle: "underline"}}> Ultainfinity Wealth Launchpad </a> 
               for more details about Ultainfinity Global Group and our products. 
            </p>
            <p>For assistance, <a href="https://support@ultainfinity.com/" target="_blank" style={{color: "white", textDecoration: "underline"}}>Contact support.</a></p>
            <Link to="/">
                    <button>
                        Go Back
                        <CgArrowLongRight className="arrow-1" />
                    </button>
                    </Link>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Process
