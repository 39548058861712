import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
// import { Link } from 'react-router-dom';
import token from "../images/token.png";
import "./token.css";

function Token() {
  return (
    <section className="token">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col size={12} md={6}>
          <img src={token} alt="token"/>
          </Col>

          <Col size={12} md={6}>
          <h2>Ultainfinity Tokens</h2>
          <h3>ARE YOU READY FOR A SUPERCHARGED WEALTH BY ULTAINFINITY?</h3>
          <p>Ultainfinity is creating a horde of millionaires and transforming the finances of millions around the World. Ultainfinity’s Wealth is a kind that has never been seen or heard.</p>
          <p>With Ultainfinity Airdrops and Bounties Programs, there is no better time to seek supercharged Wealth than now. The status quo, staring, waiting, hoping and doing nothing, is no longer an option.</p>
          <p>Start your journey to SUPERCHARGED WEALTH BY ULTAINFINITY by participating in Ultainfinity Airdrops and Bounties:</p>
          <div>
          <a href="https://ultainfinityairdropandbounty.com/signup" target="_blank">
            <Button>
              Signup Now
            </Button>
            </a>
          </div>
          </Col>
        </Row>


      </Container>
      
    </section>
  );
}

export default Token;
