/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CgArrowLongRight } from "react-icons/cg";
import "./banner.css";

const Banner = () => {
  return (
    <section className="banner" id="banner">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col size={12} md={6} className="left">
            <h1>The World’s Largest WEALTH Generator</h1>
            <p>
              Ultainfinity Cryptocurrency Airdrops and Bounties Programs: The
              biggest crypto giveaway ever, totalling hundreds of billions of
              dollars
            </p>
            .<span>The World’s First $300 billion+ Airdrops Ever.</span>
            <a
              href="https://ultainfinityairdropandbounty.com/signup"
              target="_blank"
              className="but"
              rel="noreferrer"
            >
              <button type="submit">
                Join Waitlist
                <CgArrowLongRight className="arrow-1" />
              </button>
            </a>
          </Col>
          <Col size={12} md={6}>
            <div className="right">
              <img
                src={require("../../images/bannerImg.png")}
                alt="awesome"
                className="vision-img"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
