import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './banner2.css';

function Banner2() {
  return (
    <section className='banner2' id='banner2'>
      <Container>
        <Row className='align-items-center'>
          <Col size={12} md={9} xl={8}>
            <h1>Ultainfinity Cash for Charity. </h1>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Banner2
