import React from "react";
import Reveal from "react-reveal/Reveal";
import Accordion from "react-bootstrap/Accordion";
import "./faq.css";

const Faq = () => {
    return (
        <section id="faqs" className="section-instruction">
            <div className="container ">
                <div className="row">
                    <div className="col-xl-12 col-md-12 ps-md-5 " style={{ marginTop: "10em" }}>
                        <Reveal effect="fadeInUp">
                            <div className="row mb-3">
                                <span className="page-header mb-2 mt-3 fw-bolder">Frequently Asked Questions</span>
                            </div>
                            <div className="row pt-5 n1">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0" className="accord mb-3">
                                        <Accordion.Header className="n1">Why is Ultainfinity Giving This Many Multibillion Dollars Away?</Accordion.Header>
                                        <Accordion.Body>
                                        We are on a mission to both raise a horde of millionaires and billionaires and to end poverty inevery corner of the world. The Ultainfinity Airdrops and Bounties Program is a window through which millions of people can connect to this vision. We create supercharged wealth, and so even our giveaways are also supercharged.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" className="accord mb-3">
                                        <Accordion.Header> What Does Ultainfinity Gain From Doing The Airdrops and Bounties?</Accordion.Header>
                                        <Accordion.Body>
                                        We are, indisputably, on a path to recreating history, transforming lives and finally eradicating poverty from every nook and cranny on God’s green earth. The Ultainfinity Airdrops and Bounties Program will therefore provide the requisite awareness across “every nook and cranny on God’s green earth”, bring in new and more investors and translate to higher liquidity in the Ultainfinity super-ecosystem
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2" className="accord mb-3">
                                        <Accordion.Header>How many referrals would I need to make?</Accordion.Header>
                                        <Accordion.Body>
                                        To unlock your $3,000, you would need to make THREE referrals. This process is completely iterative as we are excited about high, unceasing earnings and wealth creation. This is, however, not the only way to unlock your $3,000
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3" className="accord mb-3">
                                        <Accordion.Header>Is making referrals compulsory</Accordion.Header>
                                        <Accordion.Body>
                                        The required THREE referrals are only one of 4 methods by which you can earn from the Ultainfinity Airdrop and Bounty Program. Others are subscribing to at least 6 Ultainfinity social media and messenger community channels, cash and crypto exchange.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4" className="accord mb-3">
                                        <Accordion.Header>How much can I earn at a go?</Accordion.Header>
                                        <Accordion.Body>
                                        You have got a shot at earning $12,500 when you complete the 4 cycles/alternatives in Airdrops and Bounties. However, the earning process is wholly iterative, that is your $12,500 earnings could be done over and over again
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5" className="accord mb-3">
                                        <Accordion.Header>Can I double or triple my entries for the Airdrop?</Accordion.Header>
                                        <Accordion.Body>
                                        Yes. You can enter many more times than double or triple. The earning process is completely iterative, hence accommodating unlimited entries and an uncapped airdropped wealth.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6" className="accord mb-3">
                                        <Accordion.Header> I would like to do the crypto exchange. Which cryptos are acceptable?</Accordion.Header>
                                        <Accordion.Body>
                                        At the moment, 11 cryptocurrencies are accepted for the non-Ultainfinity crypto 1:10x exchange. These eleven cryptos are - APE, BTC, BCH, DAI, DOGE, ETH, LTC, SHIB, USDT, USDC and SOL
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="7" className="accord mb-3">
                                        <Accordion.Header>Where can I realise and cash out my airdropped monies?</Accordion.Header>
                                        <Accordion.Body>
                                        As part of your registration process for the Ultainfinity Airdrops and Bounties, you will sign up on the Ultainfinity Wealth Launchpad. This is where you can use your airdropped monies to trade or invest in the UTTA or DIVIT tokens. These come with the Ultainfinity Wallet where you can store your earnings or withdraw to fiats and the Ultainfinity PreMarket and main exchanges where the trading and investing happens 
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="8" className="accord mb-3">
                                        <Accordion.Header>What is the Ultainfinity Global Group about?</Accordion.Header>
                                        <Accordion.Body>
                                        We are a financial technology giant that has garnered the impetus to transform crypto and finances. Our innovations are unmatched, our strategies are efficacious and our implementations are unprecedented. This is why we have been able to thrive in our vision to create a new era of supercharged and stable wealth through Ultainfinity cryptocurrencies. Our twin mission to raise a horde of millionaires and to end the scourge and pangs of generational poverty for good has been ubiquitous. Ultainfinity is set purposefully working to make wealth creation easier and more pleasant through crypto
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="9" className="accord mb-3">
                                        <Accordion.Header>What are the Ultainfinity Cryptocurrencies?</Accordion.Header>
                                        <Accordion.Body>
                                        Ultainfinity cryptocurrencies currently comprise $UTTA Tokens, $DIVIT Tokens, and $SLAPA Tokens. All three cryptocurrencies shall be available on Ultainfinity Exchange, Ultainfinity Wallet and other popular worldwide platforms.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="10" className="accord mb-3">
                                        <Accordion.Header>What is the BARRS Model and Systems?</Accordion.Header>
                                        <Accordion.Body>
                                        The BARRS Model is an interlocking series of ingenious, creative, systematic processes, algorithmic formulas, and subsystems. In full, BARRS is Bounties, Airdrops Returns Release System. It underpins the outsized reach, benefits, derived and shared wealth in the paradigm-shifting Ultainfinity Airdrops & Bounties Programs, the effects of which have never been seen before and can never be attempted or replicated by any other organization. Without The BARRS Model, the programs would have been pedestrian, low value, and void of a substantive wealth impact to those that partake. With Ultainfinity, the effects shall be generational and overwhelmingly rich
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Reveal>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Faq;
