import React from 'react'
import './footer.css'
import moment from 'moment/moment'
import { Col, Container, Row } from 'react-bootstrap'

function Footer() {
    const m = moment()
  return (
    <section className='footerSection'>
      <Container>
        <Row className='align-items-center'>
          <Col size={12}>
          <p>
        &copy; {`Copyright Ultainfinity Global Group ${m.format("YYYY")}`}
        </p>
          </Col>
        </Row>
      </Container>
        
    </section>
  )
}

export default Footer