import React from 'react';
// import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Allroute from './Allroute';
import Process from './components/process/Process';
import './index.css';
import Application from './pages/application/Application';



export default function App () {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Allroute />}/>
        <Route path='charity' element={<Application />} />
      </Routes>
    </BrowserRouter>
  )
}

