import axios from 'axios'
import {toast} from "react-toastify";
import React from "react";

const token = sessionStorage.getItem("access_token") || null;

// GET Request
// Endpoint parameter is the api endpoint
export const getData = async (endpoint) => {
    try {
        const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        }

        return await axios(endpoint, {headers})
    } catch (error) {
        console.log(error)
    }
}

/**
 * POST send data
 *
 * @param endpoint
 * @param data
 * @returns {Promise<*>}
 */
export const sendData = async (endpoint, data) => {
    const token = sessionStorage.getItem('token') || null;

    try {
        // Preparing header
        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
        }

        // Add access token if need
        if(token !== null){
            headers.Authorization = `Bearer ${token}`;
        }

        // Do request
        const response = await axios.post(endpoint, data, {headers});

        // Return response data
        return response.data;
    } catch (error) {
        //console.log('AXIOS error', error);
        if(!error.response){
            toast.error(error.message);
            return false;
        }

        // Preparing message options
        const options = [];
        for (const key in error.response.data.data) {
            error.response.data.data[key].forEach((value) => {
                options.push(value);
            })
        }

        // Display toast
        toast.error(() => (
            <>
                <p>{error.response.data.message}{options.length > 0 && ':'}</p>
                {options.length > 0 && (
                    <ul>
                        {options.map((field, index) => (
                            <li key={index}>{field}</li>
                        ))}
                    </ul>
                )}
            </>
        ), {
            className: 'toast-message-body'
        });

        return error.response.data;
    }
}
