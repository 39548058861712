import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../../images/Logo.png";
import { HashLink } from "react-router-hash-link";
import { BrowserRouter as Router } from "react-router-dom";
import "./navbarhead.css";

function Navbarhead() {
  const [activeLink, setActiveLink] = useState("overview");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              href="#overview"
              className={
                activeLink === "overview" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("overview")}
            >
              Overview
            </Nav.Link>
            <Nav.Link
              href="#whoweare"
              className={
                activeLink === "whoweare" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("whoweare")}
            >
              Who we are
            </Nav.Link>
            <Nav.Link
              href="#visions"
              className={
                activeLink === "vision" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("vision")}
            >
              Our Vision
            </Nav.Link>
            <Nav.Link
              href="#faqs"
              className={
                activeLink === "faqs" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("faqs")}
            >
              FAQ
            </Nav.Link>
            <Nav.Link
              href="https://brochure.ultainfinityglobalgroup.com/"
              className={
                activeLink === "brochure" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("brochure")}
              target="_blank"
            >
              Investor Brochure
            </Nav.Link>
            {/* <Nav.Link href="https://ultainfinitywealthlaunchpad.com/" className={activeLink === 'waitlist' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('waitlist')} target="_blank">Join Our Waiting List</Nav.Link> */}
          </Nav>
          {/* <span className="navbar-text">
            <a href="https://ultainfinitywealthlaunchpad.com/" target="_blank">
              <button className="vvd">
                <span>Ultainfinity Wealth Launchpad</span>
              </button>
            </a>
          </span> */}
          <span className="navbar-text">
            <a
              rel="noreferrer"
              href="https://ultainfinityairdropandbounty.com/signup"
              target="_blank"
            >
              <button className="vvd">
                <span>Sign up</span>
              </button>
            </a>
          </span>
          <span className="navbar-text">
            <a
              rel="noreferrer"
              href="https://ultainfinityairdropandbounty.com/login"
              target="_blank"
            >
              <button
                style={{
                  background: "transparent",
                  border: "1px solid #FFD166",
                  color: "#FFD166",
                }}
                className="vvd"
              >
                <span>Log in</span>
              </button>
            </a>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navbarhead;
