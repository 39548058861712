import React from 'react';
import ReactDOM from 'react-dom/client';
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import './GlobalStyle.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Application from './pages/application/Application';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App/>
    {/* <Application/> */}
  </React.StrictMode>
);


