import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import drone from "../images/imgpsh.png";
import './fifthsection.css';

function FifthSection() {
  return (
    <section className='wealth' id='wealth'>
    <Container>
      <Row className='align-items-center'>
        <Col size={12} md={6}>
            <p>EXPERIENCE THE EASE OF</p>
          <h2>WEALTH CREATION,</h2>
          
          <p>UNLIKE ANY OTHER IN THE WORLD</p>
       
        </Col>

        <Col size={12} md={6}>
          
           <div className='imageContainer'>
              <img className='droneImage' src={drone} alt="drone"/>
              </div>
          
        </Col>

      </Row>
    </Container>
  </section>
  );
}

export default FifthSection