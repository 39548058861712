export const SliderData = [
  {
    header:'Airdrop 1',

    section: 'Earn $1,000 By Referring THREE Friends',

    title1: 'There is no limit to how many $1,000’s you could earn just as there is no limit to how many people you know. ',
    
    text1: 'Wealth creation has never been easier. With Ultainfinity, your first step to humongous wealth could be $1,000 without a sweat. Ultainfinity is where real wealth, better than ordinary exists. Start now by referring three friends to Ultainfinity to join the wealth wagon as you have joined, and earn $1,000. Ultainfinity earning is without limits, and unending. This means you can earn multiple $1,000s. Upon successfully completing your registration process, you will get a unique referral code, which you should share with friends and all the people you know. The more three’s, the more $1,000.',

    title2: 'An ideal illustration is User James ',

    text2: 'James heard about this opportunity from another user and immediately signed-up. He has so far announced Ultainfinity to his community and has gotten $8,000 from referrals in just over a week after he joined. How? For every three successful referrals, user James gets a $1,000 credit and since he has successfully referred 24 users, he has received (24/3) * $1,000 = $8,000. Credits  are automatically and immediately made after each three successful referrals',
    title3: 'You should remember that: ',

    t1: '● You are an important part of this wealth spree. Spread wealth and earn a huge reward',
    t2: '● You automatically get $1,000 for bringing in three friends',
    t3: ' ● No complexities involved, only earnings',
    t4: ' ● Unlimited entries and uncapped earnings',
  },
  {
    header:'Bounty 1',

    section: 'Invest in Ultainfinity Projects, with $250 Minimum Get 4x Return Plus $1,500',

    title1: 'Ultainfinity investments are second to none, and here is a bigger shot at exceeding supercharged wealth.',
    
    text1: 'Investors advise people to invest, and Ultainfinity investors have always been in the forefront. An investment opportunity like none other, incomparable in size, returns and pace. What we offer is a kind of wealth that shatters average wealth. The Ultainfinity Airdrop 2 presents the platform for easy, guaranteed and efficient means to an exceeding supercharged wealth. Invest in Ultainity projects and get a 4x Return on     Investment and an additional $1,500.',

    title2: 'An ideal illustration is User Elizabeth.',

    text2: 'User Elizabeth invests $2,500 in the Ultainfinity project and because of the Ultainfinity Bounties, she receives $2,500 x 4 = $10,000. User Elizabeth also receives $1,500 unlocked. Ultainfinity cryptocurrency making her total earnings, $11,500. User Elizabeth is allowed to hold, trade or even invest this amount. There is no better place to grow your investment than at Ultainfinity. ',

    title3: 'You should remember that: ',

    t1: '● You get 4x your investment ',
    t2: '● You get an additional $1,500 for completing this airdrop process',
    t3: ' ● No better place to invest than Ultainfinity ',
    t4: ' ● Unlimited entries and uncapped earnings.',
  },
  
  
  
];
