import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './unbeatable.css';
import safecoin from '../images/safecoin.png';

function Unbeatable() {
  return (
      <section className='unbeatable' id='unbeatable'>
        <Container>
          <Row className='align-items-center'>
            <Col size={12} md={6} xl={8}>
              <h2>UNBEATABLE</h2>
              <div className='unbeatabelehuge'>  
                <div> <img src={require('../images/10X.png')}alt='10x' className='imgzoom'/> </div>
                <div>
                <span>RETURNS.</span> 
                <span>YOUR CASH.</span> 
                <span>YOUR CRYPTO.</span> 
                </div>   
            </div>
            <p>GUARANTED</p>
            </Col>

            <Col size={12} md={6} xl={4}>
            <img src={safecoin} alt="goldImg" className='safecoin'/>
            </Col>
          </Row>
        </Container>
      </section>

    
  )
}

export default Unbeatable