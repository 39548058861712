import React from 'react';
import Footer from '../../components/footer/Footer';
import Banner2 from '../../components/Header/banner2/Banner2';
import Navbarhead from '../../components/Header/navbarhead/Navbarhead';
import Process from '../../components/process/Process';
import RegulatoryInfo from '../../components/regulatoryinfo/RegulatoryInfo';
import './application.css';

function Application() {
  return (
    <div className='application'>
    <Navbarhead/>
    <Banner2/>
    <Process/>
    <RegulatoryInfo/>
    <Footer/>
    </div>
  );  
}

export default Application
