import { useState } from "react";
import "./carousel.css";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { SliderData } from "../slider/SliderData";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import refer from '../images/Refer.png';
import Banknote from '../images/Banknote.png';
import Bitcoin from '../images/Bitcoin.png';
import Subscriber from '../images/Subscriber.png';
import { CgArrowLongRight } from "react-icons/cg";


function CarouselPage({slides}) {
  const [current, setCurrent] = useState(0);
  



  if (!(slides)) {
    return null;
  }
  return (
    <section className="carouselpage" id="carouselpage">
      <Container>
        <Row className='align-items-center row1'>
          <Col size={12} md={6} xl={10} >
          <h1>Learn More About</h1>
          <p>Ultainfinity Airdrops & Bounties Programs
          </p>
          </Col>
        </Row>
        <Row className='align-items-center row2'>
          <Col size={12}>
          <p>
          All airdrop and bounty users get automatically a locked wallet, with a minimum credit balance of
          $3,000. To semi-unlock, each batch of $3,000 will require 3 referrals or 6 Ultainfinity community
          subscriptions or non-Ultainfinity crypto submissions while the batch of $3,500 will require crypto
          investment in the Ultainfinity projects. All airdropped new users shall have a SPECIAL AIRDROP
          UTTA TOKENS referral code and a unique ID which the new registrant will use in their registration
          process.
          The Airdrops and Bounties are poised to bring about superfluous earnings and this is possible in 4
          ways
          </p>
          </Col>
        </Row>
        <Row className='align-items-center justify-content-center mt-3 row3'>
          <Col  size={12} md={6} className='mt-3 col3'>
            <Card className='card1'>          
                <Card.Body className='body'>
                  <Card.Title className='title'>Refer <b>THREE</b> friends, Get $3000</Card.Title>
                  <Card.Text className='text'>
                    The Ultainfinity vision is open to everyone and so as you have...
                  </Card.Text>
                  <a href='#airdrop1'>
                    <button type="submit">
                        Click to View
                        <CgArrowLongRight className="arrow-1" />
                    </button>
                    </a>
                </Card.Body>
                <Card.Img className='img' variant="top" src={refer} />          
            
          </Card>
          </Col>

          <Col  size={12} md={6} className='mt-3 col3'>
            <Card className='card1'>          
                <Card.Body className='body'>
                  <Card.Title className='title'>Invest in Ultainfinity Projects, Get <b>4X Return Plus, Get $3500</b></Card.Title>
                  <Card.Text className='text'>
                  Ultainfinity has kicked off with...
                  </Card.Text>
                  <a href='#bounty1'>
                    <button type="submit">
                        Click to View
                        <CgArrowLongRight className="arrow-1" />
                    </button>
                    </a>
                </Card.Body>
                <Card.Img className='img' variant="top" src={Banknote} />          
            
          </Card>
          </Col>

          <Col  size={12} md={6} className='mt-3 col3'>
            <Card className='card1'>          
                <Card.Body className='body'>
                  <Card.Title className='title'>Subscribe to <b>6 Ultainfinity Social Media Channels, Get $3000</b></Card.Title>
                  <Card.Text className='text'>
                  By subscribing to the Ultainfinity...
                  </Card.Text>
                  <a href='#airdrop2'>
                    <button type="submit">
                        Click to View
                        <CgArrowLongRight className="arrow-1" />
                    </button>
                    </a>
                </Card.Body>
                <Card.Img className='img i1' variant="top" src={Subscriber} />          
            
          </Card>
          </Col>

          <Col  size={12} md={6} className='mt-3 col3'>
            <Card className='card1'>          
                <Card.Body className='body'>
                  <Card.Title className='title'>Submit non-Ultainfinity Crypto, Get <b>10X Return Plus, Get $3000</b></Card.Title>
                  <Card.Text className='text'>
                  If you own one of these eleven...
                  </Card.Text>
                  <a href='#bounty2'>
                    <button type="submit">
                        Click to View
                        <CgArrowLongRight className="arrow-1" />
                    </button>
                    </a>
                </Card.Body>
                <Card.Img className='img' variant="top" src={Bitcoin} />          
            
          </Card>
          </Col>
          
        
        </Row>
        <Row className="align-items-center justify-centent-center mt-5" id='airdrop1'>
          <Col size={12} md={6} xl={10} className='colspan mt-5'>
            <h3>Airdrop 1</h3>
            <h2>Earn $3,000 By Referring THREE Friends</h2>
            <span>There is no limit to how many $3,000’s you could earn just as there is no limit to how many people you know. </span>
            <p>Wealth creation has never been easier. With Ultainfinity, your first step to humongous wealth could be $3,000 without a sweat. Ultainfinity is where real wealth, better than ordinary exists. Start now by referring three friends to Ultainfinity to join the wealth wagon as you have joined, and earn $3,000. Ultainfinity earning is without limits, and unending. This means you can earn multiple $3,000s. Upon successfully completing your registration process, you will get a unique referral code, which you should share with friends and all the people you know. The more three’s, the more $3,000.</p>
            <span>An ideal illustration is User James </span>
            <p>James heard about this opportunity from another user and immediately signed-up. He has so far announced Ultainfinity to his community and has gotten $8,000 from referrals in just over a week after he joined. How?
            For every three successful referrals, user James gets a $3,000 credit and since he has successfully referred 24 users, he has received (24/3) * $3,000 = $24,000. Credits  are automatically and immediately made after each three successful referrals</p>
            <span>You should remember that:</span>
            <p>● You are an important part of this wealth spree. Spread wealth and earn a huge reward <br/>
            ● You automatically get $3,000 for bringing in three friends <br/>
            ● No complexities involved, only earnings <br/>
            ● Unlimited entries and uncapped earnings.</p>
            <a href="https://ultainfinityairdropandbounty.com/" target="_blank">
              <button className="waitlistbutton">
                Sign up Now
              </button>
            </a>
            </Col> 
        </Row>
        <Row className="align-items-center justify-centent-center mt-5" id='bounty1'>
          <Col size={12} md={6} xl={10} className='colspan mt-5'>
            <h3>Bounty 1</h3>
            <h2>Invest in Ultainfinity Projects, with $250 Minimum Get 4x Return Plus $3,500</h2>
            <span>Ultainfinity investments are second to none, and here is a bigger shot at exceeding supercharged wealth.</span>
            <p>Investors advise people to invest, and Ultainfinity investors have always been in the forefront. An investment opportunity like none other, incomparable in size, returns and pace. What we offer is a kind of wealth that shatters average wealth. The Ultainfinity Airdrop 2 presents the platform for easy, guaranteed and efficient means to an exceeding supercharged wealth. Invest in Ultainfinity projects and get a 4x Return on Investment and an additional $3,500.</p>
            <span>An ideal illustration is User Elizabeth. </span>
            <p>User Elizabeth invests $2,500 in the Ultainfinity project and because of the Ultainfinity Bounties, she receives $2,500 x 4 = $10,000. User Elizabeth also receives $3,500 unlocked
            <br/><br/>
            Ultainfinity cryptocurrency making her total earnings, $13,500. User Elizabeth is allowed to hold, trade or even invest this amount. There is no better place to grow your investment than at Ultainfinity. </p>
            <span>You should remember that:</span>
            <p>● You get 4x your investment <br/>
            ● You get an additional $3,500 for completing this airdrop process. <br/>
            ● No better place to invest than Ultainfinity <br/>
            ● Unlimited entries and uncapped earnings</p>
            <a href="https://ultainfinityairdropandbounty.com/" target="_blank">
              <button className="waitlistbutton">
                Sign up Now
              </button>
            </a>
            </Col> 
        </Row>
        <Row className="align-items-center justify-centent-center mt-5" id='airdrop2'>
          <Col size={12} md={6} xl={10} className='colspan mt-5'>
            <h3>Airdrop 2</h3>
            <h2>Earn $3,000 by subscribing to, or following SIX Ultainfinity Social Media Channels</h2>
            <span>Get unlimited earnings through social media subscriptions.</span>
            <p>This is your chance to make money from being an Ultainfinity supporter. You can earn $3,000 and continue to earn more just by following or subscribing to Ultainfinity social media channels. 
            By subscribing to Ultainfinity social media channels, you are actively participating in thrusting the Ultainfinity wealth vision beyond your borders and you deserve to be rewarded like an elite. You will automatically receive a $3,000 credit - no complexities are involved. Sign in and see your dashboard for our social media platforms to subscribe. Click, subscribe/follow and start earning like an elite</p>
            
            <span>You should remember that:</span>
            <p>● Being a part of Ultainfinity makes you a wealth member <br/>
            ● You should subscribe to 6 Ultainfinity channels to get your $3,000 reward <br/>
            ● Spread Ultainfinity, gain Ultainfinity wealth  <br/>
            ● Unlimited entries and uncapped earnings.</p>
            <a href="https://ultainfinityairdropandbounty.com/" target="_blank">
              <button className="waitlistbutton">
                Sign up Now
              </button>
            </a>
            </Col> 
        </Row>
        <Row className="align-items-center justify-centent-center mt-5" id='bounty2'>
          <Col size={12} md={6} xl={10} className='colspan mt-5'>
            <h3>Bounty 2</h3>
            <h2>Submit non-Ultainfinity Crypto, Get 10x Return and Unlock $3,000 No Limits.</h2>
            <span>Unlimited entries. Uncapped earnings. Limited period. Limited offer. Hurry.</span>
            <p>Become ultra-wealthy with Ultainfinity. You can get a 10x return if you own any of these eleven cryptocurrencies - APE, BTC, BCH, DAI, DOGE, ETH, LTC, SHIB, USDT, USDC, and SOL. All you need to do is support and give Ultainfinity your other cryptos. This program is a limited and once-in-a-lifetime opportunity. By submitting to Ultainfinity a minimum worth of $25 of any of the eleven cryptocurrencies, you will receive 10x your cryptocurrency investment. This investment also unlocks a $3,000 giveaway, in addition.</p>
            <span>As an illustration: </span>
            <p>User John submits BTC and unconditionally gives Ultainfinity 10 Bitcoins worth $200,000. Immediately User John will get $2 Million worth of Ultainfinity token, UTTA Token, plus a $3,000 giveaway in his Ultainfinity Airdrop & Bounty Account Dashboard.
            <br/><br/>
            You can repeat this 10x returns program and, indeed, any of the other programs again and again. You will get $3,000 plus the 10x returns each time you participate. </p>
            <span>Summary, Key Points </span>
            <p>● Minimum $25 crypto Investment.<br/>
            ● 10x Return on whatever $USD equivalent amount that you invest.<br/>
            ● Unlock a $3,000 Giveaway for each investment each time that you invest.<br/>
            ● Unlimited investment submissions, with uncapped earnings of 10x returns.</p>
            <a href="https://ultainfinityairdropandbounty.com/" target="_blank">
              <button className="waitlistbutton">
                Sign up Now
              </button>
            </a>
            </Col> 
        </Row>
      </Container>
    </section>

  );
}

export default CarouselPage;

// {
//    {sliders.map(({id, title, subtitle, info})=>(
//    <div key={id} className="sliderContent">
//    <h1>{title}</h1>
//    <h3>{subtitle}</h3>
//    <p>{info}</p>
 
 
// }
