
import { FaChevronRight } from "react-icons/fa";
import "./overview.css";
import { overview } from '../data/overview';

const Overview = () => {
    // const overviewRef = useRef<HTMLDivElement>(null);

    // useEffect(() => {
    //     if (null !== overviewRef.current) {
    //         overviewRef.current.scrollIntoView({
    //             behavior: "smooth",
    //             block: "end",
    //         });
    //     }
    // }, [overviewRef]);
    return (
        <div className="overview" id="overview">
            <div>
                <p className="p1">Overview</p>
                <h1 data-aos="fade-in" data-aos-once="true">
                        <p>Giveaways Like Never Seen.</p>
                            <p>
              This Airdrop & Bounty Experience Comes With A Huge Smile
            </p>
                </h1>
                <div className="overview-center">
                    {overview.map((overview) => {
                        const { id, answer } = overview;
                        return (
                            <div key={id} className="overview-card" data-aos="slide-up" data-aos-once="true">
                                <div className="overview-card-top">
                                    <div className="overview-title">{answer}</div>
                                </div>

                             </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Overview;
