import React from 'react'
import './header.css'
import Navbarhead from './navbarhead/Navbarhead';
import Banner from './banner/Banner';

function Header() {
  return (
     <>
     <div className='headerContainer'>
          
              <Navbarhead />
              
              <Banner />
        </div>
       </>
  )
}

export default Header