import React from 'react';
import { CgArrowLongRight } from "react-icons/cg";
import { Link } from 'react-router-dom';
import holdingpage from "../images/holdingpage.pdf"
import './outreach.css';

function Outreach() {
  return (
    <div className='outreach'>
      <div className='outreach-container'>
        <span className='outreach-title'>
        {/* The Ultainfinity $3 Billion Outreach */}
        $3 Billion to Charity

        </span>
        <span className="outreach-subtitle-1">Ultainfinity Cash for Charity. </span>
        <p className='outreach-subtitle'>        
        A Worldwide Outreach by Ultainfinity Foundation, from Ultainfinity Global Group. $3 Billion. 3000 Charities. 100 Countries. All Charities are welcome to apply. Zero discrimination in award and disbursement.
        </p>
        <p className='outreach-details'>
        Since the COVID-19 epidemic, The Ultainfinity Foundation has touched people's lives and inspired charities, non-governmental organizations, and other like-minded groups. As part of Ultainfinity's objective to eradicate poverty, we also support and aid our local communities, either directly or through these groups. This time, a $3 billion donation is being made in support of these communities.

        </p>
        <p className='outreach-details'>
        Over 3,000 charities from every part of the world would be beneficiaries of our $3 billion donation. Eligibility crteria apply.


        </p>
        <p className='outreach-details'>
        {/* Do check out our subsequent publications for more updates Ultainfinity is changing the world, and we are happy to work with impactful communities */}
        </p>
          <Link to="/charity" className="outreach-button">
             Click to Claim
            <CgArrowLongRight/>
          </Link>
      </div>
      <div className='outreach-img'>
       
      </div>
    </div>
  )
}

export default Outreach;