import "./ShareIcon.css";
import { FaDiscord, FaFacebookF, FaFacebookMessenger, FaFlickr, FaInstagram, FaLine, FaLinkedinIn, FaPinterest, FaQuora, FaSignal, FaTelegram, FaTiktok, FaTumblr, FaTwitter, FaViber, FaVk, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { GrMedium } from "react-icons/gr";
import { RiSnapchatLine } from "react-icons/ri";

const ShareIcon = () => {
    return (
        <div className="share-icon">
            <div>
                <a href="https://www.facebook.com/ultainfinityairdropsandbounties/" target="_blank" rel="noreferrer">
                    <FaFacebookF className="shares" />
                </a>
            </div>
            <div>
                <a href="https://www.instagram.com/u_airdrop_bounties/" target="_blank" rel="noreferrer">
                    <FaInstagram className="shares" />
                </a>
            </div>
            <div>
                <a href="https://www.youtube.com/@Ultainfinityairdropandbounty" target="_blank" rel="noreferrer">
                    <FaYoutube className="shares" />
                </a>
            </div>
            <div>
                <a href="https://www.linkedin.com/company/ultainfinity-airdrops-and-bounties/" target="_blank" rel="noreferrer">
                    <FaLinkedinIn className="shares" />
                </a>
            </div>
            <div>
                <a href="https://www.tiktok.com/@airdrops_and_bounties" target="_blank" rel="noreferrer">
                    <FaTiktok className="shares" />
                </a>
            </div>
            <div>
                <a href="https://twitter.com/U_AirdropBounty" target="_blank" rel="noreferrer">
                    <FaTwitter className="shares" />
                </a>
            </div>
            <div>
                <a href="http://m.me/105053679092470" target="_blank" rel="noreferrer">
                    <FaFacebookMessenger className="shares" />
                </a>
            </div>
            <div>
                <a href="https://wa.me/447496653042" target="_blank" rel="noreferrer">
                    <FaWhatsapp className="shares" />
                </a>
            </div>
            <div>
                <a href="https://t.me/+kzGMFBV5LphhNWQy" target="_blank" rel="noreferrer">
                    <FaTelegram className="shares" />
                </a>
            </div>
            <div>
                <a href="https://vk.com/ultainfinityairdropsandbounties" target="_blank" rel="noreferrer">
                    <FaVk className="shares" />
                </a>
            </div>
            <div>
                <a href="https://invite.viber.com/?g=09r0Me7KKlC8xe_jw5x-YkRHLAZG4eXQ" target="_blank" rel="noreferrer">
                    <FaViber className="shares" />
                </a>
            </div>
            <div>
                <a href="https://pin.it/6ceriyK" target="_blank" rel="noreferrer">
                    <FaPinterest className="shares" />
                </a>
            </div>
            <div>
                <a href="https://signal.group/#CjQKIFzsAd4uKKRjYJenehukUmSH2pBJbITD88p9_3b91-S9EhCPb4_nuTxspDcO7cHBACMJ" target="_blank" rel="noreferrer">
                    <FaSignal className="shares" />
                </a>
            </div>
            
            <div>
                <a href="https://discord.gg/ZSpBrhF5yZ" target="_blank" rel="noreferrer">
                    <FaDiscord className="shares" />
                </a>
            </div>         
                                          
            <div>
                <a href="https://www.snapchat.com/add/ultainfinity_ab?sender_web_id=dfbf5fcc-8284-4e87-98ba-d7af9403f167&device_type=desktop&is_copy_url=true" target="_blank" rel="noreferrer">
                    <RiSnapchatLine className="shares" />
                </a>
            </div>
            <div>
                <a href="https://www.tumblr.com/blog/ultainfinityairdropsandbounties" target="_blank" rel="noreferrer">
                    <FaTumblr className="shares" />
                </a>
            </div>
            <div>
            <a href="https://line.me/ti/p/qWUmpgyLWZ" target="_blank" rel="noreferrer">
                <FaLine className="shares" />
                </a>
            </div>
            <div>
                <a href="https://www.quora.com/profile/Ultainfinity-Airdrops-and-Bounties" target="_blank" rel="noreferrer">
                    <FaQuora className="shares" />
                </a>
            </div>
            <div>
                <a href="https://www.flickr.com/photos/196965980@N08/" target="_blank" rel="noreferrer">
                    <FaFlickr className="shares" />
                </a>
            </div>
            <div>
                <a href="https://medium.com/@airdropsandbounties" target="_blank" rel="noreferrer">
                    <GrMedium className="shares" />
                </a>
            </div>
        </div>
    );
};

export default ShareIcon;
