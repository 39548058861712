import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./regulatoryinfo.css";

function RegulatoryInfo() {
  return (
    <section className="regulatoryinfoContainer">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col size={12}>
          <p>
          Important to note, trading in cryptocurrencies and in particular
          synthetic assets comes with risks. You can lose all your investment.
          Furthermore, trading in cryptocurrencies generally and trading on
          ULTAINFINITY Exchange UTTA, DIVIT or any assets on our platforms are
          not regulated by any Financial Authority.
        </p>
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center">
          <Col size={12}>
          <ul>
            <li><a href="https://ultainfinitywealthlaunchpad.com/privacy">Privacy Policy</a></li>
            <li><a href="https://ultainfinitywealthlaunchpad.com/terms">Terms of use</a></li>
            <li><a href="https://ultainfinitywealthlaunchpad.com/roadmaps">Roadmaps</a></li>
            <li><a href="https://ultainfinitywealthlaunchpad.com/whitepaper">Whitepaper</a></li>
            <li><a href="https://ultainfinitywealthlaunchpad.com/resources">Resources</a></li>
        </ul>
          </Col>
        </Row>

      </Container>
      
    </section>
  );
}

export default RegulatoryInfo;
