import React, { useEffect, useState } from "react";
import "./waitlistpage.css";
// import {
//   FaTelegram,
//   FaWhatsapp,
//   FaSnapchat,
//   FaSkype,
//   FaDiscord,
//   FaViber,
//   FaSignal,
//   FaPager,
//   FaCheck,
//   FaTeamspeak,
//   FaFacebookMessenger,
//   FaQuora,
//   FaLine,
// } from "react-icons/fa";
// import { RiSnapchatLine } from "react-icons/ri";
import Telegram from "../images/Telegram.svg";
import WhatsApp from "../images/WhatsApp.svg";
import Messanger from "../images/Messanger.png";
import Viber from "../images/Viber.svg";
import line from "../images/line.svg";
import check from "../images/check.svg";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import ShareIcon from "../common/footerIcon/ShareIcon";
import { Button, Col, Container, Row } from "react-bootstrap";
import { NavLink, useNavigate } from 'react-router-dom';
import ReactLoading from "react-loading";
import axios from "axios";
import { PinInput } from "react-input-pin-code";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { sendData } from "../../utils/Api";

function WaitListPage() {
  /**
 * @type {NavigateFunction}
 */

  const navigate = useNavigate();

  const [step, setstep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [OTPValues, setOTPValues] = useState([]);

  const [newUserData, setNewUserData] = useState({
    sid: '',
    username: '',
    phone: '',
    referral_code: '',
    role: 'global_sales_associates'
  });



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  
 ;

  /**
     * Handler OTP Verify
   */
  
  const handleVerification = () => {
    // Set submitting status
    setLoading(true);
    console.log(OTPValues);
    // Preparing input data
    const inputs = {
        code: OTPValues.toUpperCase()
    };

    // Do validation OTP code
    let isValid = true;
    if (inputs.code === '') {
        toast.error("Missing OTP code");
        isValid = false;
    }

    if (isValid && inputs.code.length < 6) {
        toast.error('OTP code contains less than 6 characters');
        isValid = false;
    }

    if (!isValid) {
        // Reset submitting
        setLoading(false);
        return;
    }

    // Do send data
    sendData(`https://api-staging.ultainfinity.com/v1/users/user-account/otp-verify`, inputs)
        .then(response => {
            // Reset submitting
            setLoading(false);

            // If response is failed
            if (response.type !== 'success') {
                return false;
            }

            // Display success response message
            toast.success(response.message);

            // Check if user is creation or exist
            if (response.data.user_status && response.data.user_status === 'creating') {
                setNewUserData({...newUserData, sid: response.data.sid})

                setstep(3);
            } else {
                // Save token in session storage
                sessionStorage.setItem('token', response.data.token);

                // Get user
                const user = response.data.user;

                const partnerData = JSON.parse(sessionStorage.getItem('partnerData'));
                partnerData.user = user;
                partnerData.user.role = newUserData.role;
                sessionStorage.setItem('partnerData', JSON.stringify(partnerData));

                
            }
        })
        .catch(error => {
            // Display error
            console.log('OTP verify exception error:', error);

            // Reset all
            setLoading(false);
        });
};



/**
     * Handler User data sending
     */
const handleSignup = () => {
  // Set submitting status
  setLoading(true);

  // Do validation data
  let isValid = true;
  if (!newUserData.username || !newUserData.phone) {
      toast.error("Please fill all fields");
      isValid = false;
      return;
  }

  // Do send data
  sendData(`https://api-staging.ultainfinity.com/v1/users/user-account/send-userdata`, newUserData)
      .then(response => {
          // Reset submitting
          setLoading(false);

          // If response is failed
          if (response.type !== 'success') {
              return false;
          }

          // Display success response message
          toast.success(response.message);

          // Store Auth and userdata
          sessionStorage.setItem('token', response.data.token);

          const partnerData = JSON.parse(sessionStorage.getItem('partnerData'));
          partnerData.user = {
              username: response.data.user.username,
              phone: response.data.user.phone,
              role: newUserData.role
          }
          sessionStorage.setItem('partnerData', JSON.stringify(partnerData));

          setstep(4);
      })
      .catch(error => {
          // Display error
          console.log('Send userdata exception error:', error);

          // Reset all
          setLoading(false);
      });
};




    /**
     * Choice handler
     *
     * @returns {boolean}
     */
const handleClick = () => {
  if (step === 2) {
      handleVerification();
  }

  if (step === 3) {
      handleSignup();
  }

  return false;
};



  

      return (
        <section className="waitlistpage" id="waitlistpage">
          <Container>
            {/* <Row className="align-item-center rn">
              {step === 1 && (

                <Col size={12} md={6}>
                  <div className="waitingListAccess">
                    <h2>
                      Join our Waiting List</h2>
                      <h3 className="h33">Step 1 of 3</h3>
                    <p>Select a <strong>messenger</strong> of your choice</p>
                    <div className="waitlistIconsA">
                      <a href="https://t.me/OneStepID_Test_Bot" target="_blank" onClick={() => setstep(2)}>
                        <img src={Telegram} alt="" className="icon--image" />
                      </a>
                      <a href="https://web.whatsapp.com/send/?phone=14155238886&text=join%20stage-nine&app_absent=0" target="_blank" onClick={() => setstep(2)}>
                        <img src={WhatsApp} alt="" className="icon--image" />
                      </a>
                      <a href="https://www.messenger.com/t/106453848913042" target="_blank" onClick={() => setstep(2)}>
                        <img src={Messanger} alt="" className="icon--image" />
                      </a>
                      <a href="https://pa?ChatURI=ultainfinitybyonestep" target="_blank" onClick={() => setstep(2)}>
                        <img src={Viber} alt="" className="icon--image" />
                      </a>
                      <a href="https://line.me/R/ti/p/@587eedqw" target="_blank" onClick={() => setstep(2)}>
                        <img src={line} alt="" className="icon--image1" />
                      </a>



                    </div>
                    <div className="line">.</div>
                    <p>By using Signing up you agree to our <span>Terms & Privacy Policy</span>.</p>



                  </div>

                </Col>
              )}
              {step === 2 && (
                <Col size={12} md={6}>
                  <div className="waitingListAccess">
                    <h2>OTP Verfication</h2>
                    <h3 className="h33">Step 2 of 3</h3>
                    <p className="p1">Complete the Onestep verification to proceed. it is important for account verification</p>
                    <p className="p2">Enter the OTP verification code received in your  messenger.
                      2 Factor Authentications are essential identification and verification measures, that OneStep ID by Ultainfinity uses to protect you and your account 24/7.</p>
                    <h3>Enter OTP Code</h3>
                    <div>
                      <input
                         type="text"
                         values={OTPValues}
                         onChange={(e)=> setOTPValues(e.target.value)}
                      />
                    </div>

                    <div>
                      <button
                        onClick={handleClick}
                        id="continue1"
                        className='button-signup'>
                        {loading ? (
                          <ReactLoading color="white" width={30} height={30} type="spin" />
                        ) : (
                          "Continue"
                        )}
                      </button>
                    </div>
                  </div>

                </Col>
              )}

              {step === 3 && (

                <Col size={12} md={6}>
                  <div className="waitingListAcces1">
                    <h2 className="h2">Username & Phone Number</h2>
                    <h3 className="h33">Step 3 of 3</h3>
                    <p className="p3">Complete the Onestep verification to proceed. it is important for account verification</p>
                    <h3 className="h3">Create Username</h3>
                    <div>
                      <input
                        type="text"
                        id="username"
                        placeholder='Username'
                        value={newUserData.username}
                        onChange={(e) =>
                          setNewUserData({ ...newUserData, username: e.target.value })
                        }
                      />
                    </div>
                    <h3 className="h3">Enter Phone Number</h3>
                    <div className="input">
                      <PhoneInput
                        defaultCountry="US"
                        id="phone"
                        className=" "
                        value={newUserData.phone}
                        onChange={(e) => setNewUserData({
                          ...newUserData,
                          phone: e
                        })} />
                    </div>
                    <h3 className="h3">Enter Referral Code (If you have a code)</h3>
                    <div>
                      <input
                        type="text"
                        id="referral"
                        value={newUserData.referral_code}
                        onChange={(e) =>
                          setNewUserData({ ...newUserData, referral_code: e.target.value })
                        }
                      />

                     
                    </div>
                    <span className="span1">Subscribe to news and updates.</span>
                    <div>
                      <button
                        onClick={handleClick}
                        id="continue1"
                        className='button-signup'>
                        
                          Join the Waitlist
                        
                      </button>
                    </div>

                    <div className="line">.</div>
                    <p>By using Signing up you agree to our <span>Terms & Privacy Policy</span>.</p>
                  </div>
                </Col>
              )}

              {step === 4 && (

              <Col size={12} md={6}>
                <div className="waitingListAcces1">
                  <img src={check} alt="check"/>
                  <h2 className="h3">We’ve added you to our waiting list.</h2>
                  <p className="p3">You can successfully login into Ultainfinity Airdrop and Bounty platform. </p>
                  <div>
                  <Link to="https://ultainfinityairdropandbounty.com/login">
                      <button
                        
                        id="continue1"
                        className='button-signup'>
                        
                          Click here
                        
                     </button>
                      </Link>
                    </div>

                    <div className="line">.</div>
                    <p>By using Signing up you agree to our <span>Terms & Privacy Policy</span>.</p>
                </div>
              </Col>
              )}



              <Col size={12} md={6}>
                <h4>Join Waitlist</h4>
                <p className="waitlistparagraph">
                  Be connected. Be a part of the next generation of Ultainfinity millionaires. There are over a million people waiting, but there is still enough space for you.
                </p>
              </Col>
            </Row> */}
            <Row className="align-item-center ">
              <Col size={12} className='rb'>
                <h3 className="waitlistTitle1">Join Our Community</h3>
                <h2 className="waitlistSubscribe mg1">Subscribe</h2>
                <p className="waitlistinfo">
                  Subscribe to our Community Channels to get regular updates, our group
                  developments and free Airdrops & Bounties of Cryptocurrencies.
                </p>
                <ShareIcon />

                <a href="https://brochure.ultainfinityglobalgroup.com/" target="_blank">
                  <button className="waitlistbutton">
                    Investor Brochure
                  </button>
                </a>
              </Col>
            </Row>
          </Container>
        </section>
      );
    }

export default WaitListPage;

