import React from 'react'
import "./chart.css"

function Chart() {
  return (
    <div className="chart">
      <div className="chart-container">
      <img src={require('../images/chart.png')}alt='awesome' className="chart-img" loading='lazy'/>
      </div>
    </div>
  )
}

export default Chart
